import Layout from '@/modules/layout/components/layout';

const CareersListPage = () =>
  import(
    '@/modules/careers/components/careers-list-page.vue'
  );
const CareersViewPage = () =>
  import(
    '@/modules/careers/components/careers-view-page.vue'
  );

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'careers',
        path: '/careers',
        component: CareersListPage,
        meta: { unauth: true },
      },
      {
        name: 'careers/:id',
        path: '/careers/:id',
        component: CareersViewPage,
        meta: { unauth: true },
      },
    ],
  },
];
