import Layout from '@/modules/layout/components/layout';

const PortfolioCategory = () =>
  import('@/modules/portfolio/components/portfolio-category.vue');
const PortfolioProduct = () =>
  import('@/modules/portfolio/components/portfolio-product.vue');

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'portfolio',
        path: '/portfolio/:category',
        component: PortfolioCategory,
        exact: true,
        meta: { auth: true },
      },
      {
        name: 'portfolio-product',
        path: '/portfolio/:category/:id',
        component: PortfolioProduct,
        exact: true,
        meta: { auth: true },
      },
    ],
  },
];
