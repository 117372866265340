const firebaseConfig = {
  apiKey: "AIzaSyDlzFRtuZgYqsuciOVNiska_1Q7r78ru5I",
  authDomain: "runprof-c02dd.firebaseapp.com",
  databaseURL: "https://runprof-c02dd.firebaseio.com",
  projectId: "runprof-c02dd",
  storageBucket: "runprof-c02dd.appspot.com",
  messagingSenderId: "265290637502",
  appId: "1:265290637502:web:fb0aaf51551991effdfeef",
  measurementId: "G-HWT7H8D8BY"
};

// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/api`;

// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
};
