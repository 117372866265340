import firebase from 'firebase/app';
import 'firebase/firestore';
// import { firestoreAction } from 'vuexfire';

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
  },
  mutations: {
    START_LOADING(state) {
      state.loading = true;
    },
    FINISH_LOADING(state) {
      state.loading = false;
    },
  },
  actions: {
    async doSubmitContactForm({ commit }, payload) {
      console.log(commit);
      var id = firebase
        .firestore()
        .collection('ids')
        .doc().id;
      var obj = {
        firstName:payload.firstName,
        lastName:payload.lastName,
        email:payload.email,
        phoneNumber:payload.phoneNumber,
        message:payload.message,
        country:payload.country,
        company:payload.company,
        nda:payload.nda,
        createdAt: firebase.firestore.Timestamp.now(),
        attachments: payload.attachments,
      };
      await firebase
        .firestore()
        .collection('contact-form')
        .doc(id)
        .set({
          ...obj,
        });
    },
  },
};
