
import Header from '@/modules/layout/components/header.vue';
// import Menu from '@/modules/layout/components/menu.vue'
export default {
  name: 'app-layout',
  components: {
    [Header.name]: Header,
    // [Menu.name]: Menu
  },
};
