
import { mapGetters, mapActions } from 'vuex';
import HeaderOnePage from './modules/layout/components/header.vue';
import firebase from 'firebase/app';

export default {
  components: {
    HeaderOnePage,
  },
  name: 'app',
  data() {
    return {
      logo: require('./assets/logo.svg'),
      logoLight: require('./assets/logoLight.svg'),
      fab: false,
      participants: [
        {
          id: 'user1',
          name: 'RunProf',
          imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/runprof%2FlogoLight.svg?alt=media&token=5979670a-32ab-4f5d-bd16-e79f506983db',
        },
        // {
        //   id: 'user2',
        //   name: 'Matteo',
        //   imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
        // },
      ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: '../../../assets/logo.svg',
      messageList: [
        {
          type: 'text',
          author: `user1`,
          data: { text: `How may we help you?` },
          suggestions: ['some quick reply', 'another one'],
        },
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: false, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#D5AA0F',
          text: '#ffffff',
        },
        launcher: {
          bg: '#D5AA0F',
        },
        messageList: {
          bg: '#ffffff',
        },
        sentMessage: {
          bg: '#D5AA0F',
          text: '#000000',
        },
        receivedMessage: {
          bg: '#000000',
          text: '#ffffff',
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867',
        },
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true,
    };
  },

  // created() {
  //   this.doInit();
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // },
  async created() {
    this.$vuetify.theme.dark = this.isDark();
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises)
      .then
      // console.log('doInitFirebase Done & doInit Done')
      ();
    if (!this.anonymousToken) {
      const firebaseUser = await firebase
        .auth()
        .signInAnonymously();
      console.log(firebaseUser.user.uid);
      this.setCurrentUser(firebaseUser)
    }

    // window.addEventListener('load', this.addToCache(['/']));
    // await this.doInit();
    // firebase.auth().languageCode = localStorage.getItem('language');
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    if (localStorage.getItem('language') == 'ar') {
      import(`quasar/lang/ar`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    } else {
      import(`quasar/lang/en-us`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    onScroll() { // onScroll(e)
      if (typeof window === 'undefined') return;
      // const top = window.pageYOffset || e.target.scrollTop || 0;
      // this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      setCurrentUser: 'layout/setCurrentUser',
      sendChatMessage: 'layout/sendChatMessage'
      //resize: 'layout/resize',
    }),
    sendMessage(text) {
      if (text.length > 0) {
        // debugger
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
        this.onMessageWasSent({
          author: 'support',
          type: 'text',
          data: { text },
        });
        // await this.sendChatMessage(text)
      }
    },
    onMessageWasSent(message) {
      // called when the user sends a message
      this.messageList = [...this.messageList, message];
      this.sendChatMessage(message)

    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 0;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      console.log('Emit typing event');
    },
    editMessage(message) {
      const m = this.messageList.find(
        (m) => m.id === message.id,
      );
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    isDark(){
      const hours = (new Date()).getHours()
      return hours >= 12 ? true : false
    },

    handleResize() {
      //this.resize({
      //   width: window.innerWidth,
      //  height: window.innerHeight,
      //});
    },
    // async addToCache(urls) {
    //   debugger
    //   const myCache = await window.caches.open('my-cache');
    //   await myCache.addAll(urls);
    // },
  },

  computed: {
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
      currentUser: 'layout/currentUser',
    }),
  },
};
