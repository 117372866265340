import Layout from '@/modules/layout/components/layout';

const BlogViewPage = () =>
  import(
    '@/modules/blog/components/blog-view-page.vue'
  );

  const BlogListPage = () =>
  import(
    '@/modules/blog/components/blog-list-page.vue'
  );

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'blog/:id',
        path: '/blog/:id',
        component: BlogViewPage,
        meta: { unauth: true },
      },
      {
        name: 'blog',
        path: '/blog',
        component: BlogListPage,
        meta: { unauth: true },
      },
    ],
  },
];
