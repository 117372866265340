
import feather from 'feather-icons';
export default {
  name: 'app-header',
  data: () => ({
    drawer: false,
    darkMode: true,
    offerIsOpen: true,

    technologiesPosition:
      document.getElementById('technologies'),
    items: [
      { title: 'Home', to: '/#home' },
      { title: 'Service', to: '/#service' },
      { title: 'About', to: '/#about' },
      { title: 'Portfolio', to: '/#portfolio' },
      { title: 'Testimonial', to: '/#tesimonial' },
      { title: 'Blog', to: '/#blog' },
      { title: 'Technologies', to: '/#technologies' },
      // { title: 'Clients', to: '/#clients' },
      // { title: 'Contact', to: '/#contact' },
      { title: 'Careers', to: '/careers' },
    ],
    icon: 'menu',
    closeIcon: 'x',
  }),
  computed: {
    isAtHome() {
      // setTimeout(() =>{
      //     return this.$route.name === 'splash-screen' ;
      // }, 1000)
      if (this.$route.name != 'splash-screen') {
        // setTimeout(() =>{
        // },1000)
      }
      return this.$route.name === 'splash-screen';
    },
  },
  methods: {
    // isAtHome() {
    //   // debugger
    //   const active = document.getElementsByClassName('v-btn--active')
    //   console.log('active',active);
    //   console.log(this.$route.name, this.$route.path);
    //   console.log( window.scrollY===0,window.scrollY);
    //   setTimeout(() =>{
    //       return this.$route.name === 'splash-screen' ;
    //   }, 500)
    //   // return this.$route.name === 'splash-screen' ;
    // },
    async goToHomePage() {
      console.log(this.$route.name);
      if (this.$route.name != 'splash-screen')
        await this.$router.push('/');
      var elmnt = document.getElementById('contact');
      elmnt.scrollIntoView();
    },
    onItemChanged(event, currentItem, lastActiveItem) {
      // here you have access to everything you need regarding that event

      if (lastActiveItem) {
        lastActiveItem.classList.remove('v-btn--active');
      }
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },

    close() {
      this.offerIsOpen = false;
    },

    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    goToCareers() {
      this.$router.push('/careers');
    },
    // goToGlobe(){
    //   this.$router.push("/globe")
    // }
  },
};
