import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  namespaced: true,

  state: {
    menuCollapsed: false,
    isMobile: false,
    currentUser: null,
    messages:[]
  },

  getters: {
    menuCollapsed: (state) => state.menuCollapsed,
    isMobile: (state) => !!state.isMobile,
    currentUser: (state) => state.currentUser,
    messages: (state) => state.messages,
    paginationLayout: (state) =>
      state.isMobile
        ? 'total, prev, pager, next'
        : 'total, sizes, prev, pager, next',
    labelPosition: (state) =>
      state.isMobile ? 'top' : undefined,
    labelWidthForm: (state) =>
      state.isMobile ? undefined : '180px',
    labelWidthFilter: (state) =>
      state.isMobile ? undefined : '120px',
  },

  mutations: {
    COLLAPSE_MENU(state) {
      state.menuCollapsed = true;
    },

    TOGGLE_MENU(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },

    RESIZE(state, payload) {
      state.isMobile = payload.width < 576;
    },
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload
    },
  },

  actions: {
    resize({ commit }, payload) {
      commit('RESIZE', payload);
    },

    toggleMenu({ commit }) {
      commit('TOGGLE_MENU');
    },

    collapseMenu({ commit }) {
      commit('COLLAPSE_MENU');
    },
    setCurrentUser({ commit },payload) {
      commit('SET_CURRENT_USER',payload);
    },
    async sendChatMessage({ getters },payload) {
      var message = {
        message: payload.data.text,
        createdAt: firebase.firestore.Timestamp.now(),
      };
      getters.messages.push(message)
      console.log(getters.currentUser);
      await firebase
        .firestore()
        .collection('chat')
        .doc(getters.currentUser.user.uid)
        .set({
          ...getters.messages,
        });
    },
  },
};
