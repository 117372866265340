import Vue from 'vue';

import './styles/quasar.scss';
import 'quasar/dist/quasar.ie.polyfills';
import iconSet from 'quasar/icon-set/fontawesome-v5.js';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import '@quasar/extras/ionicons-v4/ionicons-v4.css';
import '@quasar/extras/mdi-v4/mdi-v4.css';
import '@quasar/extras/eva-icons/eva-icons.css';

import 'quasar/dist/quasar.addon.css'
import { Quasar } from 'quasar';
// const GoogleFontsPlugin = require("google-fonts-webpack-plugin")
// const GoogleFonts = new GoogleFontsPlugin({
//   fonts: [
//     { family: "Source Sans Pro" },
//   ]
// })

import {
  Dialog,
  Notify,
  Loading,
  LocalStorage,
  SessionStorage,
  ClosePopup,
} from 'quasar'

Vue.use(Quasar, {
  animations: 'all',

  plugins: {
    Dialog,
    Notify,
    Loading,
    LocalStorage,
    SessionStorage,
    // GoogleFonts,
  },
  config: {
    notify: { /* Notify defaults */ },
    loading: { /* Loading defaults */ },
    loadingBar: {
      color: 'lima',
    },
  },
  framework: {
    iconSet: 'fontawesome-v5',
    cssAddon: true,
  },
  directives: {
    ClosePopup
  },
  iconSet: iconSet,
});