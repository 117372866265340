import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreAction } from 'vuexfire';

export default {
  namespaced: true,
  state: {
    portfolio: [],
    loading: false,
    project: {},
    nextProject: null,
    previousProject: null,
  },
  getters: {
    loading: (state) => state.loading,
    portfolio: (state) => state.portfolio,
    project: (state) => state.project,
    nextProject: (state) => state.nextProject,
    previousProject: (state) => state.previousProject,
  },
  mutations: {
    START_LOADING(state) {
      state.loading = true;
    },
    FINISH_LOADING(state) {
      state.loading = false;
    },
  },
  actions: {
    doFetchPortfolio: firestoreAction(
      async ({ bindFirestoreRef, commit }, type) => {
        commit('START_LOADING');
        const query = firebase
          .firestore()
          .collection('portfolio')
          .where('type', '==', type)
          .orderBy('order','asc')
        await bindFirestoreRef(
          'portfolio',
          query,
        );
        commit('FINISH_LOADING');
      },
    ),
    doFetchProject: firestoreAction(
        async ({ bindFirestoreRef, commit,getters }, id) => {
          commit('START_LOADING');
          var query = firebase
            .firestore()
            .collection('portfolio')
            .doc(id)
          await bindFirestoreRef(
            'project',
            query,
          );
          // NEXT PROJECT
          query = firebase
            .firestore()
            .collection('portfolio')
            .where('type','==',getters.project.type)
            .where('order','==',getters.project.order + 1)
          await bindFirestoreRef(
            'nextProject',
            query,
          );
          // PREVIOUS PROJECT
          query = firebase
            .firestore()
            .collection('portfolio')
            .where('type','==',getters.project.type)
            .where('order','==',getters.project.order - 1)
          await bindFirestoreRef(
            'previousProject',
            query,
          );
          commit('FINISH_LOADING');
        },
      ),
  },
};
