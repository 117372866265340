import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreAction } from 'vuexfire';

export default {
  namespaced: true,
  state: {
    loading: false,
    article: {},
    blog:[]
  },
  getters: {
    loading: (state) => state.loading,
    article: (state) => state.article,
    blog: (state) => state.blog,
  },
  mutations: {
    START_LOADING(state) {
      state.loading = true;
    },
    FINISH_LOADING(state) {
      state.loading = false;
    },
  },
  actions: {
    doFetchBlog: firestoreAction(
      async ({ bindFirestoreRef, commit }) => {
        commit('START_LOADING');
        const query = firebase
          .firestore()
          .collection('blog')
          .orderBy('createdAt','desc')
        await bindFirestoreRef(
          'blog',
          query,
        );
        commit('FINISH_LOADING');
      },
    ),
    doFetchHomePageBlog: firestoreAction(
        async ({ bindFirestoreRef, commit }) => {
          commit('START_LOADING');
          const query = firebase
            .firestore()
            .collection('blog')
            .orderBy('createdAt','desc')
            .limit(3)
          await bindFirestoreRef(
            'blog',
            query,
          );
          commit('FINISH_LOADING');
        },
      ),
    doFetchArticle: firestoreAction(
        async ({ bindFirestoreRef, commit }, id) => {
          commit('START_LOADING');
          var query = firebase
            .firestore()
            .collection('blog')
            .doc(id)
          await bindFirestoreRef(
            'article',
            query,
          );
          commit('FINISH_LOADING');
        },
      ),
  },
};
