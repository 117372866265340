import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreAction } from 'vuexfire';

export default {
  namespaced: true,
  state: {
    loading: false,
    vacancies: null,
    job: null
  },
  getters: {
    loading: (state) => state.loading,
    vacancies: (state) => state.vacancies,
    job: (state) => state.job,
  },
  mutations: {
    START_LOADING(state) {
      state.loading = true;
    },
    FINISH_LOADING(state) {
      state.loading = false;
    },
  },
  actions: {
    async doSubmitApplication({ commit }, payload) {
      console.log(commit);
      var id = firebase
        .firestore()
        .collection('ids')
        .doc().id;
      var obj = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phoneNumber: payload.phoneNumber,
        createdAt: firebase.firestore.Timestamp.now(),
        jobId: payload.jobId,
        resume: payload.resume,
      };
      await firebase
        .firestore()
        .collection('application')
        .doc(id)
        .set({
          ...obj,
        });
    },
    doFetchVacancies: firestoreAction(
      async ({ bindFirestoreRef, commit }) => {
        commit('START_LOADING');
        const query = firebase
          .firestore()
          .collection('careers')
          .where('status','==','open')
        await bindFirestoreRef('vacancies', query);
        commit('FINISH_LOADING');
      },
    ),
    doFetchVacancy: firestoreAction(
      async ({ bindFirestoreRef, commit },id) => {
        commit('START_LOADING');
        const query = firebase
          .firestore()
          .collection('careers')
          .doc(id)
        await bindFirestoreRef('job', query);
        commit('FINISH_LOADING');
      },
    ),
  },
};
