import { render, staticRenderFns } from "./app.vue?vue&type=template&id=4b3defcc"
import script from "./app.vue?vue&type=script&lang=js"
export * from "./app.vue?vue&type=script&lang=js"
import style0 from "./app.vue?vue&type=style&index=0&id=4b3defcc&prod&lang=scss"
import style1 from "./app.vue?vue&type=style&index=1&id=4b3defcc&prod&lang=css"
import style2 from "./app.vue?vue&type=style&index=2&id=4b3defcc&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {Scroll} from 'quasar'
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Scroll})
